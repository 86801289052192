export class Category {

    public id?: number;
    public libcat?: string;
    public desccat?: string;


    constructor(id: number, libcat: string, desccat: string) {
        this.id = id;
        this.libcat = libcat;
        this.desccat = desccat;
    }
}