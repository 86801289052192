import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppConfig } from '../app-config';
import { Subject } from 'rxjs';

@Injectable()
export class PromosService {
    public onNewMessageSent = new Subject<any>();
    constructor(private http: HttpClient, private appConfig: AppConfig) {
    }

    postPromos(data) {
        return this.http.post(this.appConfig.baseApiPath + 'promo/add', data).map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }

    getAllPromos() {
        return this.http.get(this.appConfig.baseApiPath + 'promo/list/all').map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }

    getPromo(idPromo) {
        return this.http.get(this.appConfig.baseApiPath + 'promo/details', {
            params: {
                idPromo: idPromo
            }
        }).map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }

    updatePromo(data) {
        return this.http.post(this.appConfig.baseApiPath + 'promo/update', data).map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }


    deletePromo(idPromo) {
        return this.http.delete(this.appConfig.baseApiPath + 'promo/delete', {
            params: {
                idPromo: idPromo
            }
        }).map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }

    getPromoCodeList(idPromo) {
        return this.http.get(this.appConfig.baseApiPath + 'promo/promocodes', {
            params: {
                idPromo: idPromo
            }
        }).map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }

    
    
}   