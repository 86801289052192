import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppConfig } from '../../app-config';

@Injectable()
export class DroitService {
    constructor(private http: HttpClient, private appConfig: AppConfig) {
    }

   getDroitList() {
        return this.http.get(this.appConfig.baseApiPath + 'droit/list').map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }

    
   getAgentList() {
        return this.http.get(this.appConfig.baseApiPath + 'agent/list').map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }

    updateUserDroit(data) {
        return this.http.post(this.appConfig.baseApiPath + 'agent/right/set', data).map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }
}